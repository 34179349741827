.wrapperClass {
  display: flex;
  overflow: hidden;
  width: 100%;
  
}

.slider-slide {
  margin-right: 0; 
  transition: opacity 0.5s ease;
  height: 50%;
  flex-shrink: 0;
border-radius: 20px;
 border: solid #ddd 5px ;
 
}

.slider-slide-active {
  opacity: 1;
  visibility: visible;
  border-radius: 20px;
 border: solid #FED5D6 5px ;
  
}

.slider-wrapper {
  display: flex;
   width: 100%;
  
}

.slider-container {
  width: 100%;
  overflow: hidden;
}
.slide {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.9s ease-out, transform 0.s ease-out;
}

.slide.fade-out {
  opacity: 0;
  transform: translateX(100%);
}


.slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.slider-dot-active {
  background-color: red;
}
