/*ESTILO GLOBAL*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:'Rubik';
}

html,
body,
#root {
 background-color: rgb(247, 247, 247);
  height: 100%;
}