.slider-container {
    display: flex;
    overflow: hidden;
    position: relative;
    height: 300px;
  }
  
  .slide {
    background-size: contain;
    border: 0px !important;
    position: absolute;
    top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 25px;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
  }


  .slide-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    background: rgb(241, 53, 83);
    padding: 15px;
    border-radius: 25px;
    color: "#fff !important";
  }