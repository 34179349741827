.container-slider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #1c1c1c;
  padding: 40px 0 40px;
    max-width: 700px;
    height: 340px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 700px){
    .container-slider {
      margin: 100px 10px 0;
    }
  }

  .container-button {
    position: absolute;
    text-align: center;
    bottom: 55px;
    width: 100%;
  }

  .container-button button {
    width: 240px;
    height: 36px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    cursor: pointer;
}


  .slide {
    border-radius: 8px;
    border: 1px solid red;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.slide h4 {
  display: flex;
  align-items: center;
  padding: 12px;
  height: 43px;
  font-weight: 400;
  color: white;
  background-color: #494949;

}
.packdeck-container {
  display: flex;
  padding: 12px;
  gap: 28px;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

}

.packdeck-container .packdeck p {
  color: black;
  font-weight: 500;
  font-size: 12px;
}
  .slide .image {
  border-radius: 8px;
  width: 100%;
  height: 93px;
  background-color: red;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.slide .image img {
  height: fit-content;
}
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #B8B8B8;
    margin: 0 5px;
    background: #FFF;
  }
  .dot.active {
    background: red;
  }