.container-slider-evaluate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px 0;
    max-width: 700px;
    height: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 700px){
    .container-slider-evaluate {
      margin: 100px 10px 0;
    }
  }
  .slide-evaluate {
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
   
}

.star-rating {
  position: absolute;
  bottom: 24px;
}

 .slide-evaluate .avatar { 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide-evaluate p {
  padding: 8px 0 16px;
}